import React from 'react';
import styles from "./Loader.module.css"


const Loader = () => {
    return (
        <div className={styles["loader-wrapper"]}>
            <div className={styles["loader-box"]}>
                <div className={styles["icon"]}>
                    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.3075 65.0872L23.6613 38.3421C17.2465 36.3084 15.8858 29.48 16.0072 26.3201L17.7776 11.9677C17.7776 11.3146 18.3191 10.0082 20.4851 10.0082C22.6511 10.0082 23.1579 11.3146 23.1406 11.9677V27.697C23.932 28.4597 24.6159 28.0148 24.8588 27.697L25.9002 11.5441C26.1432 11.0321 27.0457 10.0082 28.7119 10.0082C30.3781 10.0082 31.0376 11.3146 31.1591 11.9677L31.9401 27.697C32.8982 28.5868 33.6237 28.0678 33.8666 27.697V11.9677C34.0576 11.3675 34.8664 10.1353 36.5742 10.0082C38.282 9.8811 39.1255 11.2616 39.3338 11.9677L41 26.3201C40.7917 34.4548 35.8105 37.7242 33.346 38.3421L34.6997 65.1401C34.6581 67.2162 33.0509 67.9117 32.2525 68H24.9109C22.6616 67.7458 22.2381 65.9522 22.3075 65.0872Z" fill="#F7C355"/>
                        <path d="M50.2965 64.9863L51.9749 44.1021C45.429 39.6186 43.9324 32.3646 44.0023 29.2981C45.1353 15.4246 54.6849 10.6521 59.3181 10C61.626 10.3384 62.0631 12.0444 61.9932 12.8551V65.2507C61.9932 67.1541 60.2098 67.8766 59.3181 68H52.919C50.5273 67.6616 50.1741 65.8499 50.2965 64.9863Z" fill="#F7C355"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Loader;
